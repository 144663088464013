import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/home/DemoThree.vue"),
  },
  {
    path: "/en",
    name: "home-en",
    component: () => import("../views/home/DemoThree-en.vue"),
  },
  {
    path: "/news",
    name: "news",
    component: () => import("../views/portfolio/grids/GridThree.vue"),
  },

  {
    path: "/en/news-en",
    name: "news-en",
    component: () => import("../views/portfolio/grids/GridThree.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("../views/ContactUs.vue"),
  },
  {
    path: "/en/contact-en",
    name: "contact-en",
    component: () => import("../views/ContactUs-en.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: () => import("../views/CompError.vue"),
  },
  {
    path: "/en/:pathMatch(.*)*",
    name: "not-found-en",
    component: () => import("../views/CompError-en.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory("/"),
  routes,
});

router.beforeEach((to, from, next) => {
  const lang = navigator.language || navigator.userLanguage;

  if (lang.startsWith("en") && !to.path.startsWith("/en")) {
    next({ path: `/en${to.path === "/" ? "" : to.path + "-en"}` });
  } else if (lang.startsWith("zh") && to.path.startsWith("/en")) {
    next({ path: to.path.replace("/en", "").replace("-en", "") });
  } else {
    next();
  }
});

export default router;



// import { createRouter, createWebHashHistory } from "vue-router";
// // createWebHistory
// // import blog from "@/components/blog/blog.json";

// const routes = [
//   {
//     path: "/",
//     name: "home",
//     component: () => import("../views/home/DemoThree.vue"),
//   },
//   {
//     path: "/en",
//     name: "home-en",
//     component: () => import("../views/home/DemoThree-en.vue"),
//   },
//   {
//     path: "/News",
//     name: "news",
//     component: () => import("../views/portfolio/grids/GridThree.vue"),
//   },
//   {
//     path: "/contact",
//     name: "contact",
//     component: () => import("../views/ContactUs.vue"),
//   },
//   {
//     path: "/contact-en",
//     name: "contact-en",
//     component: () => import("../views/ContactUs-en.vue"),
//   },
//   {
//     path: "/:pathMatch(.*)*",
//     name: "not-found",
//     component: () => import("../views/CompError.vue"),
//   },
//   {
//     path: "/en/:pathMatch(.*)*",
//     name: "not-found-en",
//     component: () => import("../views/CompError-en.vue"),
//   },
//   // {
//   // 	path: "/main-home",
//   // 	name: "Main Home",
//   // 	component: () => import("../views/home/MainDemo.vue"),
//   // },
//   // {
//   // 	path: "/demo-2",
//   // 	name: "Demo 2",
//   // 	component: () => import("../views/home/DemoTwo.vue"),
//   // },
//   // {
//   // 	path: "/demo-3",
//   // 	name: "Demo 3",
//   // 	component: () => import("../views/home/DemoThree.vue"),
//   // },
//   // {
//   // 	path: "/about",
//   // 	name: "about",
//   // 	component: () => import("../views/AboutUs.vue"),
//   // },
//   // {
//   // 	path: "/grid-2-columns",
//   // 	name: "grid-2-columns",
//   // 	component: () => import("../views/portfolio/grids/GridTwo.vue"),
//   // },

//   // {
//   // 	path: "/what-if-gold",
//   // 	name: "what-if-gold",
//   // 	component: () => import("../views/portfolio/projects/WhatIfGold.vue"),
//   // },
//   // {
//   // 	path: "/bastian-bux",
//   // 	name: "bastian-bux",
//   // 	component: () => import("../views/portfolio/projects/BastianBux.vue"),
//   // },
//   // {
//   // 	path: "/re-styling",
//   // 	name: "re-styling",
//   // 	component: () => import("../views/portfolio/projects/ReStyling.vue"),
//   // },
//   // {
//   // 	path: "/under-armour",
//   // 	name: "under-armour",
//   // 	component: () => import("../views/portfolio/projects/UnderArmour.vue"),
//   // },
//   // {
//   // 	path: "/novara-conic",
//   // 	name: "novara-conic",
//   // 	component: () => import("../views/portfolio/projects/NovaraConic.vue"),
//   // },
//   // {
//   // 	path: "/sleep-walker",
//   // 	name: "sleep-walker",
//   // 	component: () => import("../views/portfolio/projects/SleepWalker.vue"),
//   // },
//   // {
//   // 	path: "/nile-kabutha",
//   // 	name: "nile-kabutha",
//   // 	component: () => import("../views/portfolio/projects/NileKabutha.vue"),
//   // },
//   // {
//   // 	path: "/toast-reel",
//   // 	name: "toast-reel",
//   // 	component: () => import("../views/portfolio/projects/ToastReel.vue"),
//   // },
//   // {
//   // 	path: "/time-tag-watch",
//   // 	name: "time-tag-watch",
//   // 	component: () => import("../views/portfolio/projects/TimeTagWatch.vue"),
//   // },
//   // {
//   // 	path: "/blog/:page",
//   // 	name: "blog",
//   // 	component: () => import("@/views/blog/BlogList.vue"),
//   // },
//   // {
//   // 	path: "/article/:id/:slug",
//   // 	name: "article",
//   // 	query: { xxx: 1 },
//   // 	component: () => import("../views/blog/BlogDetails.vue"),
//   // 	beforeEnter(to, from, next) {
//   // 		const id = parseInt(to.params.id);
//   // 		const LocalArticles = blog;
//   // 		const exists = LocalArticles.some((article) => article.id === id);
//   // 		if (exists) {
//   // 			next((vm) =>
//   // 				vm.getData({
//   // 					path: to.query.page,
//   // 					replace: true,
//   // 				})
//   // 			);
//   // 		} else {
//   // 			next({
//   // 				path: "not-found",
//   // 				replace: true,
//   // 			});
//   // 		}
//   // 	},
//   // },
//   // {
//   // 	path: "/slider",
//   // 	name: "slider",
//   // 	component: () => import("../views/portfolio/slider/SliderFullScreen.vue"),
//   // },
//   // {
//   // 	path: "/slider-2",
//   // 	name: "slider-2",
//   // 	component: () => import("../views/portfolio/slider/SliderPadding.vue"),
//   // },
//   // {
//   // 	path: "/slider-3",
//   // 	name: "slider-3",
//   // 	component: () => import("../views/portfolio/slider/SliderHalf.vue"),
//   // },import { log } from '../../node_modules/@types/node/util.d';
// ];

// const router = createRouter({
//   // history: createWebHistory(process.env.BASE_URL),
//   //   history: createWebHistory(`./`),
//   history: createWebHashHistory("/"), // 使用 hash 模式
//   //   base: process.env.BASE_URL,
//   routes,
// });

// router.beforeEach((to, from, next) => {
//   const lang = navigator.language || navigator.userLanguage;
//   // 判斷瀏覽器語言
//   console.log("lang", lang);
//   console.log("from", from);

//   console.log("to.path", to.path);

//   if (lang.startsWith("en") && !to.path.startsWith("/en-US")) {
//     next({ path: `/en-US${to.path === "/" ? "" : to.path + "-en"}` });
//   } else if (lang.startsWith("zh") && to.path.startsWith("/en-US")) {
//     next({ path: to.path.replace("/en", "") });
//   } else {
//     next();
//   }
// });

// export default router;
